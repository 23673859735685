import { Component, OnInit, Renderer2 } from '@angular/core';
import { DataService} from './shared/data.service';
import { Question } from './models';
import { animations } from './shared/animations';
import { NetmindService } from './shared/netmind.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [ animations ]
})
export class AppComponent implements OnInit {
  isIE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  headline: string;
  questions: Question[];
  appStarted = false;
  quizStarted = false;
  quizStopped = false;
  errMsg;

  constructor (
    private dataService: DataService,
    private netmindService: NetmindService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.dataService.getData()
      .subscribe(
        data => {
          this.headline = data.name;
          this.questions = data.questions;
          this.appStarted = true;
          },
        error => {
          this.errMsg = error;
          console.log('Error ::', this.errMsg);
        }
      );

    this.renderer.addClass(document.body, 'idg-started');
  }

  onStartQuiz() {
    this.quizStarted = true;
    this.netmindService.track(0);
  }

  quizStoppedHandler(quizStopped: boolean) {
    if (quizStopped) {
      this.quizStopped = true;
      this.quizStarted = false;
      this.renderer.setAttribute(document.body, 'class', 'idg-stopped');
      this.netmindService.track(1);
    }
  }

  onPageLeave() {
    this.netmindService.track(2);
  }
}
