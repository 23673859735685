export class Answer {
  text: string;
  isAnswer: boolean;
  selected: boolean;

  constructor(data: any) {
    data = data || {};
    this.text = data.text;
    this.isAnswer = data.isAnswer;
  }
}
