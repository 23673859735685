export class Config {
  allowBack: boolean;
  autoMove: boolean;
  pageSize: number;

  constructor(data: any) {
    data = data || {};
    this.allowBack = data.allowBack;
    this.autoMove = data.autoMove;
    this.pageSize = data.pageSize;
  }
}
