import {animate, state, style, transition, trigger} from '@angular/animations';

export const animations = [
  trigger('smoothTransition', [
    state('true',  style({ height: '*', opacity: 1 })),
    state('false', style({ height: 0, opacity: 0 })),
    transition('0 <=> 1', animate('200ms ease'))
  ]),
  trigger('smoothLongTransition', [
    state('true',  style({ height: '*', opacity: 1 })),
    state('false', style({ height: 0, opacity: 0 })),
    transition('0 <=> 1', animate('1000ms ease'))
  ]),
  trigger('slideUpDown', [
    state('true',  style({ opacity: '.8', bottom: 0 })),
    state('false', style({ opacity: 0, bottom: '-200px' })),
    transition('0 <=> 1', animate('800ms ease-in-out'))
  ])
];
