import { Config } from './config.model';
import { Question } from './question.model';

export class Quiz {
  name: string;
  description: string;
  config: Config;
  questions: Question[];

  constructor(data: any) {
    if (data) {
      this.name = data.name;
      this.description = data.description;
      this.config = new Config(data.config);
      this.questions = [];
      data.questions.forEach(q => {
        this.questions.push(new Question(q));
      });
    }
  }
}
