import { Answer } from './answer.model';

export class Question {
  code: string;
  name: string;
  copy: string[];
  answers: Answer[];
  answered: boolean;

  constructor(data: any) {
    data = data || {};
    this.code = data.code;
    this.name = data.name;
    this.copy = data.copy;
    this.answers = [];
    data.answers.forEach(a => {
      this.answers.push(new Answer(a));
    });
  }
}
