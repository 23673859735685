import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NetmindService {

  private basePath = 'angebot/vorsorge/lebensversicherung/lohntsich';
  private steps = [
    // Erstaufruf der Anwendung
    {
      documentPath: this.basePath + '/InselQuiz/01. QuizStarten (Lead-Start)',
      documentTrackingType: 'net',
      nmProcess: 'AZDE-InselQuiz-Schnellrechner',
      nmProcessCategory: 'lead-generation',
      nmProcessStep: '01. QuizStarten (Lead-Start)',
      eventType: 'action'
    },
    // Abschluss
    {
      documentPath: this.basePath + '/InselQuiz/02. QuizAbschluss (Lead-Ende)',
      documentTrackingType: 'net',
      nmProcess: 'AZDE-InselQuiz-Schnellrechner',
      nmProcessCategory: 'lead-generation',
      nmProcessStep: '02. QuizAbschluss (Lead-Ende)',
      eventType: 'action'
    },
    // Gewinnspiel
    {
      documentPath: this.basePath + '/InselQuiz/02.01. QuizAbschluss-ZumGewinnspiel',
      documentTrackingType: 'net',
      nmProcess: 'AZDE-InselQuiz-Schnellrechner',
      nmProcessCategory: 'lead-generation',
      nmProcessStep: '02.01. QuizAbschluss-ZumGewinnspiel',
      eventType: 'action'
    }
  ];

  private target = environment.production
    ? 'https://www.allianz.de/system/master/images/leer.gif'
    : 'https://wwwazd-mo-int01.allianz.de/system/master/images/leer.gif';

  constructor(private http: HttpClient) {
  }

  track(stepNr) {
    const step = this.steps[stepNr];
    const requestParams =
      '?document.path=' + encodeURIComponent(step.documentPath)
      + '&document.tracking.type=' + step.documentTrackingType
      + '&nm.process=' + step.nmProcess
      + '&nm.process.category=' + step.nmProcessCategory
      + '&nm.process.step=' + encodeURIComponent(step.nmProcessStep)
      + '&nm.mandant=AZDE'
      + '&type=' + step.eventType
      + '&url=' + encodeURIComponent(window.location.href)
      + '&=' + encodeURIComponent(this.target) + '%3F_%3D' + Date.now();

    this.http.get(
      this.target + requestParams, { headers: this.getRequestHeaders('image/gif') }
    ).subscribe();
  }

  private getRequestHeaders(accept: string = 'application/json') {
    return new HttpHeaders()
                        .set('Accept', accept)
                        .set('Content-Type', 'application/json');
  }
}
