import {Component, EventEmitter, OnInit, Output, Renderer2} from '@angular/core';
import {Question, Quiz} from '../models';
import {DataService} from '../shared/data.service';
import {Observable, Observer, Subscription} from 'rxjs';
import 'rxjs';
import {animations} from '../shared/animations';


@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css'],
  animations: [animations]
})

export class QuizComponent implements OnInit {
  quiz: Quiz = new Quiz(null);
  activeQuestion: Question;
  errMsg;
  stage = 0;
  answerSelected = false;
  answerWrong = false;
  timerSub: Subscription;

  showSlideIn: boolean;
  showIcons: boolean;
  showClaim = true;
  showQuestion: boolean;
  showQuestionText: boolean;
  showAnswerText: boolean;
  showOptionButtons: boolean;
  showRightWrongMsg: boolean;
  showNextButtons: boolean;
  showNextButton: boolean;
  showNextQuestionButton: boolean;
  showStopQuizButton: boolean;
  showResult: boolean;

  @Output() quizStopped = new EventEmitter();

  constructor(private dataService: DataService,
              private renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.resetShowStates();
    this.dataService.getData()
    .subscribe(
      data => {
        this.quiz = data;
        this.activeQuestion = this.quiz.questions[0];
        this.setBodyClasses();
      },
      error => {
        this.errMsg = error;
        console.log('Error ::', this.errMsg);
      }
    );

    const obs = this.startTimer(200, 1800);
    this.showOptions(obs);
    this.quizStopped.emit(false);
  }

  onSelectAnswer(selectedAnswer) {
    this.stage += 1; // 1
    this.answerSelected = true;
    selectedAnswer.selected = true;
    this.activeQuestion.answered = true;
    this.showRightWrongMsg = true;
    this.answerWrong = false;

    if (!selectedAnswer.isAnswer) {
      this.answerWrong = true;
    }

    const timer = this.startTimer(1500, 1600);
    this.timerSub = timer.subscribe(
      (data: string) => {
        if (data === 'timer1') {
          this.stage += 1; // 2
          this.answerSelected = false;
          this.showClaim = false;
          this.showAnswerText = true;
          this.showRightWrongMsg = false;
          this.showQuestionText = false;
        } else if (data === 'timer2') {
          this.showNextButtons = true;
          this.showNextButton = true;
        } else {
          this.timerSub.unsubscribe();
        }
      },
      (error: string) => {
        console.log(error);
      }
    );
  }

  onShowResult() {
    this.showAnswerText = false;
    this.showOptionButtons = false;
    this.showNextButton = false;
    this.showClaim = true;
    this.showResult = true;
    this.stage += 1; // 3
    const questionIndex = this.quiz.questions.indexOf(this.activeQuestion);
    if (questionIndex < this.quiz.questions.length - 1) {
      this.showNextQuestionButton = true;
    } else {
      this.showStopQuizButton = true;
    }
  }

  onShowNextQuestion() {
    const nextQuestionIndex = this.quiz.questions.indexOf(this.activeQuestion) + 1;
    this.activeQuestion = this.quiz.questions[nextQuestionIndex];
    this.setBodyClasses();
    this.stage = 0;
    this.answerSelected = false;
    this.showClaim = false;
    this.showResult = false;
    this.showNextButton = false;
    this.showNextButtons = false;
    this.showNextQuestionButton = false;
    this.showIcons = false;
    this.showSlideIn = true;
    const obs = this.startTimer(200, 1800);
    this.showOptions(obs);
  }

  setBodyClasses() {
    this.renderer.setAttribute(document.body, 'class', 'idg-quiz idg-' + this.activeQuestion.code);
  }

  startTimer(time1: number, time2) {
    return Observable.create((observer: Observer<string>) => {
      setTimeout(() => observer.next('timer1'), time1);
      setTimeout(() => observer.next('timer2'), time2);
      setTimeout(() => observer.next(null), time2 + 100);
      setTimeout(() => observer.complete(), 4000);
    });
  }

  showOptions(timer) {
    this.timerSub = timer.subscribe(
      (data: string) => {
        if (data === 'timer1') {
          this.showSlideIn = true;
        } else if (data === 'timer2') {
          this.showSlideIn = false;
        } else {
          this.renderer.addClass(document.body, 'blur');
          this.showSlideIn = false;
          this.showIcons = true;
          this.showQuestion = true;
          this.showClaim = true;
          this.showQuestionText = true;
          this.showOptionButtons = true;
          this.timerSub.unsubscribe();
        }
      },
      (error: string) => {
        console.log(error);
      }
    );
  }

  onStopQuiz() {
    this.quizStopped.emit(true);
    this.resetShowStates();
  }

  btnState(option) {
    let btnState: string;
    if (this.stage === 0 || (this.stage === 1 && !option.selected)) {
      btnState = 'neutral';
    }
    if (this.stage === 1 && option.selected && !option.isAnswer) {
      btnState = 'wrong';
    }
    if ((this.stage === 1 && option.selected && option.isAnswer) || (this.stage === 2 && option.isAnswer)) {
      btnState = 'right';
    }
    if ((this.stage === 2 && !option.isAnswer && !option.selected) || (this.stage === 2 && !option.isAnswer)) {
      btnState = 'deactivated';
    }

    return btnState;
  }

  resetShowStates() {
    this.showSlideIn = false;
    this.showIcons = false;
    this.showClaim = true;
    this.showQuestion = false;
    this.showQuestionText = false;
    this.showAnswerText = false;
    this.showOptionButtons = false;
    this.showRightWrongMsg = false;
    this.showNextButtons = false;
    this.showNextButton = false;
    this.showNextQuestionButton = false;
    this.showStopQuizButton = false;
    this.showResult = false;
  }
}

