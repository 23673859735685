import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor (private http: HttpClient) { }

  getData(): Observable<any> {
    return this.http.get('./assets/data.json')
      .pipe(
        map(res => res),
        catchError((e: Response) => throwError(e))
      );
  }
}
